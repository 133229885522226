import styled from '@emotion/styled'

import {APP_NAME} from 'utils/constants'


export interface Props {
  admin?: boolean
}

const Logo = styled(({admin, ...props}: Props) => <h1 {...props}>{APP_NAME + (admin ? ' ADMIN' : '')}</h1>)`
  margin: 0;
  cursor: pointer;
  color: ${({theme}) => theme.color};
`

export default Logo
